.admin-formulari-list-item {
  width: 100%;
  min-height: 50px;
  display: flex;
  height: auto;
}

.admin-formulari-list-item:not(:last-of-type) {
  border-bottom: var(--mainBorder);
}

.admin-formulari-list-item-cell {
  width: 30px;

  border-right: var(--mainBorder);
  color: var(--mainColor);
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.options-buttons-block {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.options-buttons-block-button {
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  width: 15px;
}

.options-buttons-block-button img {
  display: block;
  width: 100%;
}

.options-buttons-block-button:hover {
  cursor: pointer;
}
