.poslovni-procesi-table-list {
  display: flex;
  border-top: 1px solid var(--mainColor);
}

.poslovni-procesi-table-item {
  position: relative;
  min-height: 50px;
  height: auto;
  text-align: center;
  color: var(--mainColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.poslovni-procesi-table-item:not(:last-of-type) {
  border-right: 1px solid var(--mainColor);
}

.selected-owner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: flex-start;
}

.poslovni-procesi-table-input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  color: var(--mainColor);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 26px;
  padding-bottom: 5px;
  font-size: 16px;
  resize: none;
  font-family: "Inter", sans-serif;
}
