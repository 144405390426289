.admin-formulari-rizika-list {
  width: 100%;
  border-bottom: var(--mainBorder);
  border-right: var(--mainBorder);
}

.admin-formulari-rizika-list-header {
  width: 100%;
  border-bottom: var(--mainBorder);
  border-right: var(--mainBorder);
  display: flex;

  min-height: 50px;
  height: auto;
  font-size: 14px;
  color: var(--mainColor);
  font-weight: bold;
}
.admin-formulari-rizika-list-header-item {
  padding: 0 5px;
  height: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-formulari-rizika-list-header-item {
  border-right: var(--mainBorder);
}

.add-btn-large {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.add-btn-large-disabled {
  filter: grayscale(80%);
}

.add-btn-large img {
  display: block;
  width: 80%;
}
