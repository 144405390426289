.poslovni-procesi {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.units-list-buttons-wrapper {
  width: 25%;
}

.buttons-block {
  width: 100%;
  display: flex;
  margin-top: 1em;
}

.poslovni-procesi-tables {
  width: 65%;
}

.admin-poslovni-procesi-tables {
  width: 90%;
}

.admin-poslovni-procesi-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.poslovni-procesi-organization-dropdown {
  position: relative;
  width: 100%;

  border: 1px solid var(--mainColor);
}

#no-border {
  border-bottom: none;
}

.add-poslovni-proces-btn {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--mainColor);
  font-size: 20px;
  display: flex;
  margin-top: 1.2em;
  align-items: center;
}
.add-poslovni-proces-btn img {
  width: 20px;
  display: block;
  margin-right: 5px;
}

.add-poslovni-proces-btn:hover {
  cursor: pointer;
}

.add-poslovni-proces-btn-disabled {
  color: #eee;
}
