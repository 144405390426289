.section-header-wrapper {
  margin: 2em 0;
}

.section-header-title {
  color: var(--mainColor);
  font-size: 32px;
  margin-bottom: 5px;
}

.section-header-description {
  color: var(--mainColor);
  font-size: 16px;
}
