.mape-procesa-aktivnost-list-header {
  background-color: #f0f8ff;
  display: flex;
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
}

.mape-procesa-aktivnosti-list {
  border-left: 1px solid var(--mainColor);
  border-right: 1px solid var(--mainColor);
}

.add-activity-button-wrapper {
  position: relative;
}

.empty-activity-error {
  position: absolute;
  left: 0;
  top: -20px;
  color: red;
  font-size: 12px;
}
