.center-dropdown-wrapper {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--mainColor);
  margin-bottom: 20px;
}

.center-dropdown-wrapper-children {
  width: 100%;
}
.center-dropdown-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f8ff;
  width: 100%;
  text-align: center;
  color: var(--mainColor);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  padding: 1em;
  border-bottom: 1px solid var(--mainColor);
}
