.authorization-titles-list {
  display: flex;
  justify-content: flex-start;
  height: 51px;
  border-bottom: 1px solid var(--mainColor);
  border-right: 1px solid var(--mainColor);
  color: var(--mainColor);
  font-weight: bold;
  align-items: center;
}

.authorization-titles-list-item {
  text-align: right;
  padding-right: 5px;
}

.add-new-user-btn {
  outline: none;
  border: none;
  background-color: transparent;
  color: var(--mainColor);
  font-weight: bold;
  font-size: 1em;
  margin: 10px 0 10px 10px;
}

.add-new-user-btn-disabled {
  color: #d9d9d9;
}

.add-new-user-btn:hover {
  cursor: pointer;
  color: var(--secondaryColor);
}

.add-new-user-btn-disabled:hover {
  color: #d9d9d9;
  cursor: auto;
}
