.back-arrow {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.back-arrow img {
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.back-arrow a {
  text-decoration: none;
  color: var(--mainColor);
  font-weight: bold;
}
