.page {
  padding: 2em 0;
  width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 150px);
}

.admin-page {
  padding: 2em 0 0 0;
  width: 90%;
  margin: 0 auto;
  min-height: calc(100vh - 150px);
  display: flex;
}

.admin-page-child-wrapper {
  width: calc(100% - 300px);
  min-height: 100%;
  border-left: 1px solid var(--mainColor);
}

.page-title {
  color: var(--mainColor);
  font-size: 32px;
  margin-bottom: 5px;
}

.page-description {
  color: var(--mainColor);
  font-size: 16px;
}

.page-main {
  width: 100%;
  display: flex;
  padding: 2em 0;
}

.flex-start {
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  align-items: flex-start;
}

.list-wrapper {
  margin-right: 4em;
  width: 450px;
  margin-bottom: 1em;
}

.list-title {
  color: var(--mainColor);
  text-transform: capitalize;
  font-size: 16px;
  margin-left: 5px;
}

.add-to-list-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.add-to-list-input-wrapper {
  display: flex;
  width: 400px;

  border-bottom: 1px solid var(--mainColor);
  align-items: flex-start;
}

.add-to-list-input-wrapper textarea::placeholder {
  opacity: 0.3;
  font-size: 12px;
}

.add-to-list-input {
  box-sizing: border-box;
  flex: 1;
  border: none;
  font-family: "Inter", sans-serif;
  color: var(--mainColor);
  font-size: 14px;
}

.add-to-list-input::placeholder {
  opacity: 0.3;
  font-size: 12px;
}

.add-to-list-input:focus {
  border-bottom: none;
  outline: none;
}

.add-list-button {
  color: var(--secondaryColor);
  font-size: 12px;
  border: none;
  background-color: transparent;
  outline: none;
}

.add-list-button:hover {
  cursor: pointer;
}

.positions-list {
  display: flex;
  flex-direction: column;
}

.positions-list li {
  margin-bottom: 10px;
}

.strukcture-list {
  display: flex;
  flex-direction: column;
}

.folded {
  display: none;
}

.odseci-list {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.services-list {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.edit-buttons-block {
  color: var(--mainColor);
  width: 75px;
  display: flex;
  justify-content: flex-end;
}

.edit-buttons-block button {
  background: transparent;
  outline: none;
  border: none;
  color: var(--mainColor);
  margin-left: 5px;
  width: 20px;
}

.edit-buttons-block button img {
  display: block;
  width: 70%;
}

.edit-buttons-block button:hover {
  cursor: pointer;
}

.link-arrow {
  width: 20px;
  display: block;
  transform: rotate(180deg);
  height: 100%;
  margin-right: 10px;
}

.link-arrow:hover {
  opacity: 0.5;
}
