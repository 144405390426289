.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
}

.loading-circle {
  width: 200px;
  height: 200px;

  animation: rotate 3s linear infinite;
}

.loading-circle img {
  width: 100%;
  display: block;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
