.table {
  border: 1px solid var(--mainColor);
  margin-top: 50px;
}

.table-header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  background-color: #f0f8ff;
}

.table-header-item {
  color: var(--mainColor);
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  padding: 2em;
  display: flex;
  justify-content: center;
}

.table-header-item:not(:last-of-type) {
  border-right: 1px solid var(--mainColor);
}
