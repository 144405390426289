.startpage {
  padding: 2em 0;
}
.startpage-title {
  color: var(--mainColor);
  font-size: 32px;
  margin-bottom: 5px;
}

.startpage-description {
  color: var(--mainColor);
  font-size: 16px;
}

.startpage-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2em 0;
}

.startpage-poster {
  width: 50%;
}

.startpage-poster-image {
  display: block;
  width: 100%;
}
