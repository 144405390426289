.calendar-wrapper {
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.calendar-button {
  cursor: pointer;
  padding-right: 7px;
}
.react-datepicker-wrapper {
  height: 50px !important;
}

.react-datepicker__input-container {
  height: 50px !important;
}

.react-datepicker__input-container input {
  height: 50px !important;
  padding-left: 5px;
}

.react-datepicker__input-container input:focus {
  border: 1px solid black !important;
  outline: none !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker {
  border: 1px solid var(--mainColor) !important;
}

.react-datepicker__header {
  background-color: white !important;
}
