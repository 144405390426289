.designation-description-popup {
  position: absolute;
  z-index: 400;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 500px;
  transform: translate(-50%, -50%);
  border: var(--mainBorder);
  background-color: white;
  padding: 5px;
  color: var(--mainColor);
  overflow: auto;
}

.close-designation-description-popup {
  position: absolute;
  left: 30%;
  top: 15%;
  width: 15px;
  z-index: 200;
}

.close-designation-description-popup img {
  display: block;
  width: 20px;
}

.close-designation-description-popup img:hover {
  cursor: pointer;
  filter: brightness(80%);
}
