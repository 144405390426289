.notifications {
  border: 1px solid var(--mainColor);
  border-bottom: none;
  border-left: none;
}

.notifications-header {
  height: 50px;
  border-bottom: 1px solid var(--mainColor);
  display: flex;
  justify-content: flex-end;
  color: var(--mainColor);
}

.sort-options-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
}
.sort-options-dropdown-selected {
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.sort-options-dropdown-selected:hover {
  cursor: pointer;
}

.sort-options-dropdown-options {
  border: 1px solid var(--mainColor);
  position: absolute;
  z-index: 120;
  right: 0;
  left: 0;
  top: 100%;
}

.sort-options-dropdown-options-item {
  background-color: #fff;
  padding: 10px 5px;
  border-bottom: 1px solid var(--mainColor);
}

.sort-options-dropdown-options-item:last-of-type {
  border-bottom: none;
}

.sort-options-dropdown-options-item:hover {
  cursor: pointer;
  color: var(--secondaryColor);
}
