.dropdown-wrapper {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--mainColor);
}

.dropdown-wrapper-children {
  width: 70%;
  min-height: 70px;
}
.dropdown-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f8ff;
  width: 30%;
  text-align: center;
  color: var(--mainColor);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  padding: 1em;
}
