.startpage-form-wrapper {
  width: 30%;
}
.startpage-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.textfield {
  display: block;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid var(--mainColor);
  border-radius: 10px;
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  color: var(--mainColor);
}

.textfield:focus {
  outline: none !important;
  border-width: 2px !important;
}

.error-border {
  border: 1px solid #ff0000;
}

.form-field-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.form-field-label {
  display: block;
  color: var(--mainColor);
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.startpage-form-button {
  display: block;
  width: 100px;
  outline: none;
  background-color: var(--mainColor);
  color: white;
  border: none;
  padding: 15px;
  border-radius: 20px;
  font-size: 16px;
}

.startpage-form-button:hover {
  cursor: pointer;
}

.show-password-button {
  position: absolute;
  right: 5px;
  top: 50%;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 20px;
}

.show-password-button img {
  display: block;
  width: 100%;
}

.slash {
  width: 1px;

  background-color: var(--mainColor);
  display: block;
  transform: rotate(45deg);
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: 45%;
}

.text-small {
  color: var(--mainColor);
  font-size: 12px;
}
