.processes-header {
  border: 1px solid var(--mainColor);
  border-left: none;
  display: flex;
  color: var(--mainColor);
}

.org-unit-title {
  border-right: 1px solid var(--mainColor);
  width: 30%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
