.login-form-wrapper {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
}
.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.login-textfield {
  display: block;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  color: var(--mainColor);
}

.login-form-field-wrapper {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.login-form-field-label {
  display: block;
  color: var(--mainColor);
  margin-bottom: 5px;
  font-size: 18px;
  width: 100%;
  text-align: center;
  font-weight: bolder;
}

.login-form-button {
  display: block;
  min-width: 150px;
  width: 40%;
  outline: none;
  background-color: white;
  color: var(--mainColor);
  border: 1px solid var(--mainColor);
  padding: 15px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
}

.login-form-button:hover {
  cursor: pointer;
  background-color: var(--secondaryColor);
  box-shadow: 0px 4px 4px 0px #4b9cd340;
}

.login-show-password-button {
  position: absolute;
  right: 5px;
  top: 40%;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.login-form-link-wrapper {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.login-form-link {
  color: var(--mainColor);

  display: block;
}

.checkbox {
  border: 1px solid var(--mainColor);
  width: 13px;
  height: 13px;
  display: block;
  margin-right: 5px;
  cursor: pointer;
  user-select: none;
}

.checkbox img {
  display: block;
  width: 100%;
}

.text {
  color: var(--mainColor);
}

.nemate-nalog-block {
  height: 50px;
  background-color: white;
  width: 100%;
}
