.sidebar {
  border: 1px solid var(--mainColor);
  border-right: none;
  width: 300px;
  background-color: #f0f8ff;
}

.sidebar-navigation-item {
  border-bottom: 1px solid var(--mainColor);
  width: 100%;
  text-transform: uppercase;
  color: var(--mainColor);
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  background-color: #f0f8ff;
}

.sidebar-navigation-item-active {
  background-color: white;
}

.sidebar-navigation-item:last-of-type {
  border-bottom: none;
}

.sidebar-navigation-item-link {
  color: var(--mainColor);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.sidebar-navigation-item-link-active {
  color: var(--secondaryColor);
}

.sidebar-navigation-item-icon {
  display: inline-block;
  width: 16px;
  margin-right: 10px;
}

.sidebar-navigation-item-arrow {
  display: inline-block;
  width: 16px;
}

.sidebar-navigation-subitems-list {
  padding: 5px 0;
}
.sidebar-navigation-subitem {
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
