.submit-btn {
  display: block;
  width: 100px;
  outline: none;
  background-color: var(--mainColor);
  color: white;
  border: none;
  padding: 15px;
  border-radius: 20px;
  font-size: 16px;
}

.submit-btn:hover {
  cursor: pointer;
}

.btn-disabled {
  background-color: #d9d9d9;
}
