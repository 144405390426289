.add-department-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--secondaryColor);
  font-size: 16px;
  display: block;
}
.department-icon {
  margin-right: 10px;
  margin-top: 2px;
  display: block;
  width: 20px;
}

.add-position-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--secondaryColor);
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.add-position-button:hover {
  cursor: pointer;
}

.add-department-button:hover {
  cursor: pointer;
}

.input-wrapper {
  position: relative;
  display: flex;
  width: 400px;
  border-bottom: 1px solid var(--mainColor);
  margin-bottom: 15px;
  align-items: flex-start;
}

.department-title-input {
  font-size: 16px;
  color: var(--mainColor);
  border: none;
  outline: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  flex: 1;
}

.unit-error-border {
  border-bottom: 1px solid red;
}

.odsek-title-input {
  font-size: 16px;
  color: var(--mainColor);
  border: none;
  outline: none;
  font-family: "Inter", sans-serif;

  width: 100%;
}

.service-title-input {
  font-size: 16px;
  color: var(--mainColor);
  border: none;
  outline: none;
  font-family: "Inter", sans-serif;

  width: 100%;
}
.edit-department-buttons-block {
  width: 75px;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  height: 100%;
  color: var(--mainColor);
  padding-bottom: 4px;
}

.edit-department-buttons-block button {
  background: transparent;
  outline: none;
  border: none;
  color: var(--mainColor);
  margin-left: 5px;
  width: 20px;
  cursor: pointer;
}

.edit-department-buttons-block button img {
  width: 70%;
  display: block;
}

.add-odsek-button {
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--secondaryColor);
  font-size: 16px;
  margin-bottom: 15px;
  margin-left: 40px;
}

.add-odsek-button:hover {
  cursor: pointer;
}

.plus-circle-icon {
  margin-right: 3px;
}

.add-service-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--secondaryColor);
  font-size: 14px;
  display: block;
  margin-left: 60px;
  margin-bottom: 10px;
}

.add-service-button:hover {
  cursor: pointer;
}

.position-description {
  outline: none;
  width: 400px;
  padding: 5px;
  color: var(--mainColor);
  border: 1px solid var(--secondaryColor);
  font-family: "Inter", sans-serif;
  border-top: none;
}

.position-description::placeholder {
  opacity: 0.3;
  font-size: 12px;
  font-family: "Inter", sans-serif;
}

.unfold-list-arrow {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: -3px;
}
