.custom-text-area-wrapper {
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--mainColor);
  position: relative;
}
.custom-text-area {
  border: none;
  resize: none;
  display: block;
  min-height: 50px;
  color: var(--mainColor);
  padding: 1em;
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

.custom-text-area:focus {
  outline: none;
}

.custom-text-area-name-error {
  position: absolute;
  left: 5px;
  bottom: 5px;
  color: red;
  font-size: 12px;
}

.custom-text-area-description-error {
  position: absolute;
  left: calc(35% + 5px);
  bottom: 5px;
  color: red;
  font-size: 12px;
}
