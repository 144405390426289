.contact {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-block {
  text-align: center;
  margin-bottom: 100px;
  color: var(--secondaryColor);
  font-size: 18px;
}

.contact-block p {
  margin-bottom: 5px;
}
