.list-title-wrapper {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.new-designation-block {
  display: flex;
  width: 400px;
  margin: 10px 0;
  flex-direction: column;
}

.new-designation-input-wrapper {
  display: flex;

  border-bottom: 1px solid var(--mainColor);
  align-items: flex-start;
}

.add-list-button-disabled {
  color: #eee;
}

.error-border-bottom {
  border-bottom: 1px solid red;
}
