.textarea-wrapper {
  width: 100%;

  min-height: 70px;

  position: relative;
}

.dynamic-textarea {
  display: block;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  min-height: 70px;
  color: var(--mainColor);
  padding: 5px;
  font-size: 16px;
  resize: none;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

.dynamic-textarea-auto {
  display: block;
  border: none;
  outline: none;
  width: 100%;

  min-height: 70px;
  color: var(--mainColor);
  padding: 5px;
  font-size: 16px;
  resize: none;
  font-family: "Inter", sans-serif;
}

.dynamic-textarea::placeholder {
  color: var(--mainColor);
  font-weight: 700;
}

.textarea-label {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--mainColor);
  font-weight: 700;
  font-size: 12px;
}

.text-area-wrapper {
  width: 100%;
  display: flex;
}

.text-area-title {
  width: 35%;
  color: var(--mainColor);
  border-right: 1px solid var(--mainColor);
  border-bottom: 1px solid var(--mainColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-error-message {
  position: absolute;
  color: red;
  left: 5px;
  bottom: 5px;
  font-size: 12px;
}
