.link-popup {
  width: 400px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f8ff;
  border: 1px solid var(--mainColor);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(75, 156, 211, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  color: var(--mainColor);
  z-index: 1000;
}

.link-popup-input-wrapper {
  margin-bottom: 30px;
  width: 80%;
}

.link-popup-label {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.link-popup-input {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(75, 156, 211, 0.25);
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 15px;
  color: var(--mainColor);
}

.link-popup-input::placeholder {
  opacity: 0.3;
}

.link-popup-buttons {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
