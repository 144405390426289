.mapedokumentacijaitem-button {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

.document-path-link {
  width: 100%;
  color: var(--mainColor);
  font-size: 14px;
  padding: 10px 3px 0 5px;
  text-align: left;
  margin-bottom: 10px;
}
