.notification {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid var(--mainColor);
  color: var(--mainColor);
}

.notification .notification-message {
  width: 70%;
}

.notification-options-dropdown {
  width: 100px;
  position: relative;
}
