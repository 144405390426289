.procedure-list-item:not(:last-of-type) {
  border-bottom: 1px solid var(--mainColor);
}

.new-procedure-list-item {
  display: flex;
  width: 100%;
  border: 1px solid var(--mainColor);
  border-top: none;
  box-sizing: border-box;
}

.add-new-procedure-button:hover {
  cursor: pointer;
  color: "var(--secondaryColor)";
}

.procedure-item-value {
  width: 100%;
  min-height: 70px;
  color: var(--mainColor);
  padding-top: 24px;
  padding-left: 10px;
  border-right: 1px solid var(--mainColor);
  cursor: pointer;
}

.procedure-item-value:hover > .procedure-item-value-link {
  text-decoration: underline;
  font-weight: bold;
  color: var(--secondaryColor);
}

.procedure-item-value-link-active {
  text-decoration: underline;
  font-weight: bold;
}
