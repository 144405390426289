.empty-cell {
  width: 100%;
  height: 20px;
}

.static-list {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 7px;
}
.static-list li {
  color: var(--mainColor);
}

.broj {
  color: var(--mainColor);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errormessage {
  color: red;
  font-size: 14px;
  padding-left: 5px;
  margin-top: 5px;
}
