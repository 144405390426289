body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --mainColor: #4b9cd3;
  --secondaryColor: #87ceeb;
  --mainBorder: 1px solid #4b9cd3;

  font-family: "Inter", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100vh;
}

ul {
  list-style: none;
}
