.noinput-calendar-wrapper {
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.noinput-calendar-wrapper .react-datepicker__input-container input {
  display: none !important;
}

.noinput-calendar-button {
  position: absolute;
  top: 7px;
  right: 0;
  cursor: pointer;
  width: 30px;
}
