.success-registration {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.success-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--mainColor);
  width: 500px;
  height: 300px;
  box-shadow: 0px 4px 4px 0px #4b9cd340;
  border-radius: 10px;
}

.success-popup-message {
  color: var(--mainColor);
  margin-bottom: 20px;
  text-align: center;
}

.bold {
  font-weight: bold;
}
