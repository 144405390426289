.employees-list-header {
  width: 100%;
  display: flex;
  min-height: 50px;
  padding-left: 1em;
}

.employees-list-wrapper {
  border-bottom: 1px solid var(--secondaryColor);
}

.employees-list-title {
  color: var(--mainColor);
  font-weight: 700;
}

.employees-list-title:not(:last-of-type) {
  width: calc(92% / 5);
}

.employees-list-title:last-of-type {
  flex: 1;
}

.employees-list {
  width: 100%;
  min-height: 60px;
  box-sizing: border-box;
  display: flex;
}

.employees-list:not(:last-of-type) {
  border-bottom: none;
}

.employees-list-item:not(:last-of-type) {
  border-right: 1px solid var(--secondaryColor);
  width: calc(92% / 5);
}

.employees-list-item:last-of-type {
  flex: 1;
  padding: 10px;
}

.employees-list-input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  color: var(--mainColor);
  padding-left: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

#employees-dropdown {
  position: relative;
}

.employee-item-options-button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: 20%;
}

.employee-item-options-button img {
  display: block;
  width: 100%;
}

.selected-unit {
  width: 100%;
  padding-left: 5px;
  color: var(--mainColor);
  display: flex;
  align-items: center;
  height: 100%;
}
.options-block {
  min-height: 100%;
  display: flex;
  justify-content: space-around;
}

.designation-description {
  width: 100%;
  position: relative;
  font-size: 12px;
  color: var(--mainColor);

  max-height: 70px;
  padding: 10px;
  overflow: hidden;
}

.designation-description-show {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: black;
  opacity: 0.6;
  cursor: pointer;
  color: white;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
}

.designation-description:hover > .designation-description-show {
  display: flex;
}

.dropdown-arrow {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-48%);
  cursor: pointer;
}

.opis-radnog-mesta-btn {
  text-align: center;
  border: none;
  background-color: transparent;
  outline: none;
  display: block;
  color: var(--mainColor);
  border-bottom: var(--mainBorder);
}

.opis-radnog-mesta-btn:hover {
  color: var(--secondaryColor);
  border-bottom-color: var(--secondaryColor);
  cursor: pointer;
}
