.add-button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  color: var(--mainColor);
  margin-top: 10px;
  font-size: 20px;
  cursor: pointer;
}

.add-button img {
  width: 20px;
  margin-right: 5px;
}

.add-button-disabled {
  color: #eee;
}

.new-procedure-row {
  border: var(--mainBorder);
  border-top: none;
}

.new-procedure-options-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: 100%;
  padding: 10px 0;
  align-items: center;
}

.add-position-button-disabled {
  color: #eee;
}

.active-mapa-procesa-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: var(--mainBorder);
}

.edit-mapa-procesa-button {
  background-color: transparent;
  border: none;
  outline: none;
  width: 18px;
  cursor: pointer;
}

.edit-mapa-procesa-button:not(:last-of-type) {
  margin-right: 10px;
}

.edit-mapa-procesa-button img {
  width: 100%;
  filter: brightness(80%);
}

.edit-mapa-procesa-button img:hover {
  filter: brightness(100%);
}
