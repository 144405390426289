.authorization-employee-item {
  border-bottom: 1px solid var(--mainColor);
  border-right: 1px solid var(--mainColor);
  display: flex;
  min-height: 80px;
}

.authorization-employee-item-cell {
  position: relative;
}

.authorization-employee-item-cell:not(:last-of-type) {
  border-right: 1px solid var(--mainColor);
}

.authorization-employee-item-input {
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  height: 100%;
  padding: 10px 5px;
  color: var(--mainColor);
}
