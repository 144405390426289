.employees-list-section {
  margin-bottom: 2em;
  border-bottom: 1px solid var(--mainColor);
}

.add-employee-button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--mainColor);
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 2em;
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.add-employee-button-disabled {
  color: #eee;
}

.add-employee-button img {
  width: 15px;
  margin-right: 5px;
}

.add-employee-button:hover {
  cursor: pointer;
}

.disabled-button {
  filter: grayscale(100%);
  opacity: 0.2;
}
