.dropdown-departments-list {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid var(--secondaryColor);
  width: 120%;
  padding: 10px 0;
  max-height: 200px;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 100;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dropdown-departments-list::-webkit-scrollbar {
  width: 0 !important;
}

.dropdown-departments-list p:hover {
  cursor: pointer;
}

.dropdown-sections-list {
  margin-left: 10px;
}
.dropdown-services-list {
  margin-left: 20px;
}

.dropdown-item {
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid var(--secondaryColor);
  color: var(--mainColor);
  padding: 5px 0 9px 0;
}

.dropdown-item:last-of-type {
  border: none;
  padding: 5px 0 0px 0;
}

.dropdovn-item-level-0 {
  margin-left: 5px;
}

.dropdovn-item-level-1 {
  margin-left: 15px;
}

.dropdovn-item-level-2 {
  margin-left: 25px;
}

.dropdovn-item-level-3 {
  margin-left: 35px;
}
