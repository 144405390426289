.registri-rizika-row {
  display: flex;
  border-bottom: var(--mainBorder);
}

.registri-rizika-row-item {
  border-right: var(--mainBorder);
  width: 100px;
  color: var(--mainColor);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 5px;
}
