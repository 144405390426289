.poslovni-procesi-unit-list {
  width: 100%;
  height: 350px;
  border-bottom: 1px solid var(--mainColor);
  overflow-y: auto;
}

.poslovni-procesi-unit-list ::-webkit-scrollbar {
  width: 10px;
}

.poslovni-procesi-unit-list-title {
  color: var(--mainColor);
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  border-bottom: 1px solid var(--mainColor);
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.poslovni-procesi-unit-list-item {
  color: var(--secondaryColor);
  font-size: 16px;
  font-weight: 600;
  padding: 0.5em 0;
  border-bottom: 1px solid var(--mainColor);
  display: flex;
  justify-content: space-between;
}

.poslovni-procesi-unit-list-item:hover {
  cursor: pointer;
}

.active-unit {
  color: var(--mainColor);
  font-weight: bold;
}

.new-proces-unit {
  color: red;
}

.add-unit-btn {
  font-size: 15px;
  color: var(--secondaryColor);
}

.add-unit-btn-disabled {
  color: #eee;
  font-size: 15px;
}

.poslovni-procesi-unit-list-delete-button {
  width: 15px;
  cursor: pointer;
}

.poslovni-procesi-unit-list-delete-button:hover {
  filter: grayscale(1);
}

.poslovni-procesi-unit-list-delete-button img {
  width: 100%;
  display: block;
}

.unit-name:hover {
  color: var(--mainColor);
}
