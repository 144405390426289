.registri-rizika {
  position: absolute;
  top: 50px;
  left: 0;
}

.registri-header {
  display: flex;
  background-color: #f0f8ff;
  border: var(--mainBorder);

  border-left: none;
  height: 51px;
  width: auto;
}

.registri-header-cell {
  color: var(--mainColor);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.registri-header-cell:not(:last-of-type) {
  border-right: var(--mainBorder);
}

.registri-header-cell-subtitle {
  border-right: var(--mainBorder);
  color: var(--mainColor);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex-direction: column;
}

.registri-header-cell-subtitle-span {
  font-size: 12px;
}
