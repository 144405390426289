.admin-list-wrapper {
  margin-right: 2em;
  width: 180px;
}

.bullet {
  width: 130px;
  background-color: var(--mainColor);
  color: #fff;
  border-radius: 20px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.bullet-active {
  background-color: #fff;
  color: var(--mainColor);
  border: 1px solid var(--mainColor);
}

.bullet img {
  display: inline-block;
  width: 15px;
  margin-right: 5px;
}

.bullet:hover {
  cursor: pointer;
}

.admin-positions-list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.admin-input-wrapper {
  position: relative;
  width: 100%;
}

.admin-position-description {
  outline: none;
  width: 100%;
  padding: 3px;
  color: var(--mainColor);
  border: 1px solid var(--secondaryColor);
  margin-top: -12px;
}
